import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import jwtDecode from 'jwt-decode';
import forge from 'node-forge';


// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer, { initialState } from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';

import { getPublicKey, loginUser, logoutUser, registerUser } from 'api/auth';
import { getUserProfile } from 'api/getUserProfile.api';

export const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = async (serviceToken, refreshToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
    } else {
        localStorage.removeItem('serviceToken');
    }

    if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const refreshToken = window.localStorage.getItem('refreshToken');
                const serviceToken = window.localStorage.getItem('serviceToken');
                if (refreshToken && verifyToken(refreshToken)) {
                    await setSession(serviceToken, refreshToken);
                    const response = await getUserProfile();
                    const { user } = response;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                    await logout();
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
                await logout();
            }
        };

        init();
    }, []);

    const encryptPassword = async(password) => {
        if (window.location.protocol === "http:") {
            return await encryptPasswordForge(password);
        } else {
            return await encryptPasswordSubtle(password);
        }
    }

    const encryptPasswordForge = async (password) => {
        const publicKeyPEM = await getPublicKey();

        // Extract the key data from PEM format
        const publicKeyData = publicKeyPEM
            .replace('-----BEGIN PUBLIC KEY-----', '')
            .replace('-----END PUBLIC KEY-----', '')
            .replace(/\s/g, ''); // Remove whitespaces

        // Convert to forge public key
        const publicKeyForge = forge.pki.publicKeyFromPem('-----BEGIN PUBLIC KEY-----' + publicKeyData + '-----END PUBLIC KEY-----');

        // Convert password string to a forge ByteBuffer
        const passwordBuffer = forge.util.createBuffer(password);

        // Encrypt password with public key
        const encryptedPassword = publicKeyForge.encrypt(passwordBuffer.getBytes(), "RSA-OAEP", {
            md: forge.md.sha256.create(),
            mgf1: {
                md: forge.md.sha1.create()
            }
        });

        // Convert encrypted password to a base64 string
        return forge.util.encode64(encryptedPassword);
    };

    const encryptPasswordSubtle = async (password) => {
        // Send encrypted username and password to backend
        const publicKeyPEM = await getPublicKey();

        // Extract the key data from PEM format
        const publicKeyData = publicKeyPEM
            .replace('-----BEGIN PUBLIC KEY-----', '')
            .replace('-----END PUBLIC KEY-----', '')
            .replace(/\s/g, ''); // Remove whitespaces

        // Convert the key data from Base64 to ArrayBuffer
        const publicKeyBuffer = Uint8Array.from(atob(publicKeyData), (c) => c.charCodeAt(0)).buffer;

        // Convert PEM-formatted public key to CryptoKey object
        const cryptokey = await window.crypto.subtle.importKey(
            'spki', // type of key
            publicKeyBuffer,
            {
                name: 'RSA-OAEP',
                modulusLength: 2048,
                publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
                hash: 'SHA-256'
            },
            true, // key not extractable
            ['encrypt'] // key usage
        );

        // Encrypt the password
        const encoder = new TextEncoder();
        const encryptedPassword = await window.crypto.subtle.encrypt(
            {
                name: 'RSA-OAEP'
            },
            cryptokey,
            encoder.encode(password)
        );

        // Convert the encrypted password to base64
        return btoa(String.fromCharCode(...new Uint8Array(encryptedPassword)));
    };

    const login = async (username, password) => {
        const encryptedPassword = await encryptPassword(password);

        const response = await loginUser({ username, password: encryptedPassword });
        const { access_token, refresh_token } = response;
        try {
            await setSession(access_token, refresh_token);
        } catch (error) {
         console.error(error);
        } finally {
            const result = await getUserProfile()
            const user = result[0];
            dispatch({
                type: LOGIN,
                payload: {
                    user,
                    isLoggedIn: true
                }
            });
        }
    };

    const register = async (email, firstName, lastName, username, password, phone, bio, reasonForJoining, countryOfOrigin) => {
        const userData = {
            firstname: firstName,
            lastname: lastName,
            username: username,
            email: email,
            password: await encryptPassword(password),
            reasonForJoining: reasonForJoining,
            bio: bio,
            phone: phone,
            countryOfOrigin: countryOfOrigin
        };
        return await registerUser(userData)
    };

    const logout = async () => {
        // Retrieve the service token from local storage
        const refreshToken = localStorage.getItem('refreshToken');

        // If refresh token exists, decode it to extract the jti
        if (refreshToken) {
            const decodedToken = jwtDecode(refreshToken);
            const tokenID = decodedToken.jti;
            await logoutUser(tokenID);
        }

        await setSession(null);
        localStorage.removeItem('roles');
        localStorage.removeItem('refreshToken');
        dispatch({ type: LOGOUT });
    };

    const resetPassword = async (email) => {
        //console.log(email);
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, dispatch }}>
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
